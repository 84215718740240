import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Distpicker from 'v-distpicker'

Vue.component('v-distpicker', Distpicker)
// 引入组件库模块
import ElementUI from 'element-ui'
import '@/assets/icon/iconfont.css'
import '@/assets/css/global.css'


import XLSX from 'xlsx'
// 注册
import '@/utils/rem'
import '@/request/http.js'
import smartParse from 'vue-smart-parse';
Vue.use(smartParse)
Vue.use(ElementUI)

Vue.config.productionTip = false
// Vue.prototype.$http = http
Vue.prototype.XLSX = XLSX


Vue.prototype.$store = store
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
