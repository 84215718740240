import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeIndex: "/",
    UnViewMessageNum: 0,
    agreementVisible: false,
    agreementVisible2: false,
    isCollapse: false, // 菜单栏是否折叠
    shopInfo: JSON.parse(localStorage.getItem("shopInfo")) || {},
    axiosCancelArr: []
  },
  mutations: {
    changIndex(state, nodeVal) {
      state.activeIndex = nodeVal;
    },
    setAgreementVisible(state, flag) {
      state.agreementVisible = flag;
    },
    setAgreementVisible2(state, flag) {
      state.agreementVisible2 = flag;
    },
    setNum(state, nodeVal) {
      state.UnViewMessageNum = nodeVal;
    },
    changeCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
    handleShopInfo(state, shopInfo) {
      // 设置shopInfo
      state.shopInfo = shopInfo;
      localStorage.setItem("shopInfo", JSON.stringify(shopInfo));
    },
    PUSH_CANCEL(state, cancel) {
      state.axiosCancelArr.push(cancel.cancelToken);
    },
    CLEAR_CANCEL(state) {
      state.axiosCancelArr.forEach(e => {
        e && e();
      });
      state.axiosCancelArr = [];
    }
  },
  actions: {
    setActiveIndex({ commit, state }, name) {
      commit("changIndex", name);
    },
    setAgreementVisibleFlag({ commit, state }, flag) {
      commit("setAgreementVisible", flag);
    },
    setAgreementVisibleFlag2({ commit, state }, flag) {
      commit("setAgreementVisible2", flag);
    },
    setUnViewMessageNum({ commit, state }, num) {
      commit("setNum", num);
    },
    setShopInfoData({ commit, state }, info) {
      commit("handleShopInfo", info);
    },
    setCollapse({ commit, state }) {
      commit("changeCollapse");
    },
    pushCancel({ commit }, cancel) {
      commit("PUSH_CANCEL", cancel);
    },
    clearCancel({ commit }) {
      commit("CLEAR_CANCEL");
    }
  },
  // // 2. getters
  getters: {
    // 参数列表state指的是state数据
    activeIndex(state) {
      return state.activeIndex;
    },
    UnViewMessageNum(state) {
      return state.UnViewMessageNum;
    },
    shopInfo(state) {
      console.log(state.shopInfo, 1);
      return state.shopInfo;
    },
    agreementVisible(state) {
      return state.agreementVisible;
    },
    agreementVisible2(state) {
      return state.agreementVisible2;
    },
    isCollapse(state) {
      return state.isCollapse;
    }
  },
  modules: {}
});
