import axios from "axios";
import router from "../router/index";
import store from "../store/index";
import { message } from "element-ui";
import Vue from "vue";
import qs from "qs";
import { BASE_URL2 } from "./request.config";

const instance = axios.create({
  baseURL: BASE_URL2,
  timeout: 300000
});

// 添加请求拦截器
instance.interceptors.request.use(
  function(config) {
    config.cancelToken = new axios.CancelToken(cancel => {
      store.dispatch("pushCancel", { cancelToken: cancel });
    });
    // eslint-disable-next-line eqeqeq
    if (config.method == "post") {
      config.data = qs.stringify(config.data);
    }
    var token = window.localStorage.getItem("token");
    // eslint-disable-next-line eqeqeq
    if (
      config.url != "/merapi/login/login" &&
      config.url != "/merapi/shop/apply" &&
      !token
    ) {
      router.push("/login");
    }
    if (token && config.url != "/merapi/login/login") {
      config.headers.Authorization = token;
    }

    // 在发送请求之前做些什么
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function(response) {
    // eslint-disable-next-line eqeqeq
    if (response.data.status == 102) {
      message.error("登陆超时，请重新登录");
      sessionStorage.clear();
      localStorage.clear();
      router.push("/login");
      store.dispatch("clearCancel");
    }
    // 对响应数据做点什么
    return response;
  },
  function(err) {
    if (axios.isCancel(err)) {
      // 取消请求的情况下，终端Promise调用链
      return new Promise(() => {});
    }
    // 对响应错误做点什么
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "错误请求";
          break;
        case 102:
          sessionStorage.clear();
          localStorage.clear();
          router.push("/login");
          err.message = "登陆超时，请重新登录";
          break;
        default:
          err.message = `连接错误${err.response.status}`;
      }
      console.log(err);
      return Promise.reject(err);
    }
  }
);

Vue.prototype.$http2 = instance;
