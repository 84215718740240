import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import {
  Notification
} from 'element-ui';
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'layout1',
    redirect: '/login',
    children: [{
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home.vue')
      },
      {
        path: '/message',
        name: 'message',
        component: () => import('@/views/Message.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/Account.vue')
      },
      {
        path: '/contract',
        name: 'contract',
        component: () => import('@/views/Contract.vue')
      },
      {
        path: '/regional',
        name: 'regional',
        component: () => import('@/views/Regional.vue')
      },
      {
        path: '/stock',
        name: 'stock',
        component: () => import('@/views/Stock.vue')
      },
      {
        path: '/statement',
        name: 'statement',
        component: () => import('@/views/Statement.vue')
      },
      {
        path: '/purchase',
        name: 'purchase',
        component: () => import('@/views/Purchase.vue')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/Product.vue')
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/Order.vue')
      },
      {
        path: '/book',
        name: 'book',
        component: () => import('@/views/StockBookList.vue')
      },
      {
        path: '/share',
        name: 'share',
        component: () => import('@/views/ShareStock.vue')
      },
      {
        path: '/price',
        name: 'price',
        component: () => import('@/views/PriceControl.vue')
      },
      {
        path: '/workorder',
        name: 'workorder',
        component: () => import('@/views/WorkOrder.vue')
      },
      // {
      //   path: '/generation',
      //   name: 'generation',
      //   component: () => import('@/views/Generation.vue')
      // },
      {
        path: '/stoparea',
        name: 'stoparea',
        component: () => import('@/views/StoppingArea.vue')
      }
    ],
    component: () => import('@/views/Layout1.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import('@/views/Apply.vue')
  },
  {
    path: '/info',
    name: 'baseInfo',
    component: () => import('@/views/BaseInfo.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 路由前置守卫
router.beforeEach((to, from, next) => {

  // 如果没有，userinfo是null的信息
  const token = window.localStorage.getItem('token')
  let shopInfo = JSON.parse(window.localStorage.getItem('shopInfo')) || {};
  let reminderVisible = localStorage.getItem('reminderVisible')
  let is_check = shopInfo.is_check || 0;
  // 非登录状态，访问非登录页面，就强制登录
  let pathList = ['/login', '/apply', '/info']
  if (pathList.includes(to.path)) {
    return next()
  }
  if (!token && to.path !== '/login') {
    return next('/login')
  }
  if (to.path == '/message') {
    Notification.closeAll()
    localStorage.setItem('msg_id', '')
  }
  if (reminderVisible == 1 && to.path == '/contract') {
    store.dispatch('setAgreementVisibleFlag', true)
  }
  if (is_check != 1 && to.path == '/order') {
    store.dispatch('setAgreementVisibleFlag2', true)
    return next(from.path)
  }
  store.dispatch('setActiveIndex', to.path)

  next() // 默认放行
})

export default router
